<template>
    <div class="bravo">
        <p class="typing-text" v-html="formattedText"></p>
        <a v-if="isTextFullyTyped" class="download-button" href="../CV202308.pdf" download>
            <img src="../assets/faire-defiler.png" alt="parchemin">
        </a>
        </div>
</template>

<script>
export default {
    name: 'InsideView',
    data() {
        return {
        words: [
        "Félicitation !",
        " ",
        "Merci d'avoir pris le temps d'aller jusqu'au bout de cette expérience.",
        "Comme vous l'avez compris par le biais de ce jeu entièrement fait sur mesure, je vous présente ma candidature.",
        "Votre univers regroupe énormément d'activités qui m'anime comme la créativité, le travail manuel, le design d'espace ...",
        "Je suis sur que cela a attisé votre curiosité, téléchargez mon CV pour prendre contact en vue d'une collaboration.",
        "Cordialement",
        " ",
        "Nathan lesueur"
        ],
        formattedText: "",
        isTextFullyTyped: false,
        wordIndex: 0,
        charIndex: 0,
        typingSpeed: 40, // Vitesse de frappe en millisecondes
        initialDelay: 1500,
        delayBetweenWords: 1500
        };
    },
    mounted() {
    setTimeout(() => {
        this.startTyping();
    }, this.initialDelay);
    },
    methods: {
    startTyping() {
        const typingInterval = setInterval(() => {
        if (this.wordIndex < this.words.length) { 
            const word = this.words[this.wordIndex];
            if (this.charIndex < word.length) {
            this.formattedText += word[this.charIndex];
            this.charIndex++;
            } else {
            this.formattedText += "<br>"; // Ajouter un retour à la ligne après chaque mot
            this.wordIndex++;
            this.charIndex = 0;
            setTimeout(() => {
              this.startTyping(); // Démarrer la saisie du mot suivant après le délai
            }, this.delayBetweenWords);
            clearInterval(typingInterval); // Arrêter l'intervalle de frappe pendant le délai
            }
        } else {
        clearInterval(typingInterval);
        this.isTextFullyTyped = true; // Marquer le texte comme entièrement tapé
        }
        }, this.typingSpeed);
    }
    }
};
</script>

<style lang="scss">

@font-face {
    font-family: 'typemachine';
    src: url('../fonts/typemachine.ttf') format('woff2');
}

* {
    font-family: 'typemachine', sans-serif;
    line-height: 2;
}

.bravo{
    overflow-x: hidden;
    .typing-text {
    display: inline-block;
    padding: 2em;
    margin: 0;
    position: relative;
    width: 100%;
    max-width: 80%;
    text-align: left;
    color: white;
    margin-bottom: 2em;
    }

    .typing-text::after {
        content: ".";
        opacity: 0;
        animation: blink-caret 0.75s step-end infinite;

    }
    .download-button {
        position: absolute;
        bottom: 2em;
        right: 2em;
        display: flex;
        width: 40px;
        height: 40px;
        padding: 10px;
        background-color: #cc9933;
        border-radius: 5px;
        margin: 10px;
        img{
            object-fit: contain;
        }
    }
}

@media (min-width: 500px) {
    .bravo{
        .download-button {
            position: absolute;
            bottom: 20px;
            right: 20px; /* Ajustez la position pour les écrans plus larges */
        }
    }
}    

@keyframes blink-caret {
    from, to {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

</style>
